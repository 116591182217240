$brand-black: #222;
$brand-offBlack: #333;
$brand-middleGray: #555;
$brand-gray: lightslategray;
$brand-lightGray: #eee;
$brand-green: #5cb85c;
$brand-darkGreen: #204820;
$brand-blue: #337ab7;
$brand-lightBlue: #5bc0de;
$brand-darkYellow: #F2BC57;
$brand-red: #D9483B;
$brand-white: white;
$brand-orange: #D9483B;
$brand-yellow: #F2EB85;

$brand-training: #fce29d;
$brand-charter: #8bbfb7;
$brand-owner: #D9CDBF;
$brand-maintenance: #F16A24;
$brand-positioning: #d2cce2;
$brand-medical: #a0caff;
$brand-unknown: #000;

$break-mid : 1200px;