input:focus, button:focus, a:focus, .btn:focus {
  outline:none;
  box-shadow:none
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat !important;
  background-size: 10px auto, 100% !important;
  background-position: calc(100% - 3px) !important;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path class="fill-gray" d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"/></svg>') !important;
}

html.logged-in {
  min-width:1000px;
  background-color: $brand-lightGray;
  background-position: center center;
  background-image: url("/assets/images/bg.png");
  background-size:cover;
}

p {
  margin: 0;
  padding: 0;
}