.pro-table-basic {
  width:100%;
  border-collapse: collapse;
  position: relative;

  tr:nth-child(even) {
	td, th[scope=row] {
		background: lighten($brand-lightGray, 3%);
	}
  }

  th {
	border-bottom: 2px solid $brand-gray;
	font-weight: bold;
	padding:6px;

	&:last-child {
	  text-align: right;
	}
	&:first-child {
	  text-align: left;
	}

	&[scope=row] {
	  border-bottom: none;
	  padding:unset;
	}

	&.rotated {
	  white-space: nowrap;

	  > div {
		transform: rotate(-70deg);
		width: 30px;
	  }
 	}
  }

  td {
	text-align: center;
	align-self: stretch;
	justify-self: stretch;
	border-bottom: 1px solid $brand-white;

	&:last-child {
	  text-align: right;
	}
	&:first-child {
	  text-align: left;
	}
  }

  &.plain {
	td {
	  border-bottom: none;
	}

	tr:nth-child(even) {
	  td, th[scope=row] {
		background: inherit;
	  }
	}
  }

  tr.striped, tr.striped:nth-child(even) {
	td, th[scope=row] {
	  background: lighten($brand-lightGray, 3%);
	}
  }
}