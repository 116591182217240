@import 'variables';

.background-gray {
  background: $brand-lightGray !important;
  color: $brand-middleGray !important;
}

.background-darkGray {
  background: $brand-middleGray !important;
  color: $brand-white !important;
}

.background-black {
  background: $brand-black !important;
  color: $brand-white !important;
}

.background-blue {
  background: $brand-blue !important;
  color: $brand-white !important;
}

.background-lightBlue {
  background: $brand-lightBlue !important;
  color: $brand-white !important;
}

.background-orange {
  background: $brand-orange !important;
  color: $brand-white !important;
}

.background-green {
  background: $brand-green !important;
  color: $brand-white !important;
}

.background-red {
  background: $brand-red !important;
  color: $brand-white !important;
}

.background-darkYellow {
  background: $brand-darkYellow !important;
  color: $brand-black !important;
}

.mono {
  font-family: "Courier New", monospace;
  font-weight: 200;
}

.font-sm {
  font-size: 80%;
}

.font-lg {
  font-size: 135%;
}

.font-xl {
  font-size: 150%;
}

.font-normal {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.bold, .strong {
  font-weight: bold;
}

.btn-cancel {
  background: $brand-gray;
  color: $brand-white;
}

.icon-margin {
  margin-right: 7px;
}

.bg-primary {
  color: #fff !important;
  background: $brand-blue !important;
}
.bg-success {
  color: #fff !important;
  background: $brand-green !important;
}
.bg-success-muted {
  background: lighten($brand-green, 38%) !important;
}
.bg-info {
  background: $brand-lightBlue !important;
}
.bg-warning {
  color: #000 !important;
  background: $brand-darkYellow !important;
}
.bg-danger {
  color: #FFF !important;
  background: $brand-red !important;
}
.bg-white {
  background: $brand-white !important;
}
.bg-black {
  color: #fff !important;
  background: #444 !important;
}
.bg-accent {
  color: $brand-lightGray !important;
  background: $brand-middleGray !important;
}
.bg-secondary {
  color: #fff !important;
  background: $brand-offBlack !important;
}
.bg-gray-strip {
  background: repeating-linear-gradient(49deg, rgba(120,120,120,0.5), rgba(120,120,120,0.5) 5px, transparent 5px, transparent 13px) !important;
}