.container {
  margin: 1rem auto 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
  align-items: baseline;
  justify-items: center;
  margin: 0 -8px;

  > * {
	margin: 0 8px;
	align-self: stretch;
	justify-self: stretch;
	flex-grow: 1;
	text-align: center;

	> i {
	  margin-right: 6px;
	}
  }

  table {
	text-align: left;
  }

  &.wrap {
	flex-wrap: wrap;
  }

  &.no-margin {
	margin: 0;

	> * {
	  margin:0;
	}
  }

  &.stack-left {
	justify-content: left;
  }
}

.grid-column {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items:center;
  justify-items:stretch;

  &.two {
  	grid-template-columns: 1fr 1fr;
  }
  &.three {
	grid-template-columns: 1fr 1fr 1fr;
  }
  &.four {
	grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.grid-top {
	align-items: start;
  }

  .first {
	grid-column: 1;
  }
  .second {
	grid-column: 2;
  }
  .third {
	grid-column: 3;
  }

  &.small-first {
	grid-template-columns: auto 1fr;
  }
}

.label-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4pt;
  align-self: start;
  align-items: baseline;

  .control-label {
	text-align: right;
	font-weight: bold;
	font-size: smaller;
  }
  .text {
	text-align: left;
  }
}

.grid-left {
  justify-self: start;
}
.grid-center {
  justify-self: center;
}
.grid-right {
  justify-self: end;
}
.grid-top {
  align-self: start;
}
.grid-middle {
  align-self: center;
}
.grid-bottom {
  align-self: end;
}

.grid-vertical-break {
  width: 1px;
  background: lightslategray;
  height: 100%;
  margin: 0 10px;
}

.grid-horizontal-break {
  height: 1px;
  background: lightslategray;
  width: 100%;
  margin: 10px 0;
}

.items-center {
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-width {
  grid-column: 1 / -1;
}