.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

.input-group-adjust {
  position: relative;
  top: 6px;
}

.invisible {
  visibility: hidden;
}

.fill-white {
  fill: $brand-white;
}

.fill-gray {
  fill: $brand-middleGray;
}

.fill-danger {
  fill: $brand-red;
}

.fill-warning {
  fill: $brand-darkYellow;
}

.fill-info {
  fill: $brand-lightBlue;
}

.bg-warning-sub {
  background: lighten($brand-darkYellow, 30%);
}

.bg-primary-muted {
  background: lighten($brand-blue, 35%);
}

.bg-info-sub {
  background: lighten($brand-lightBlue, 35%);
}

.underlined {
  border-bottom: 1px solid $brand-lightGray;
}

.strike {
  text-decoration: line-through;
}

.small {
  font-size: 10pt;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-muted {
  color: $brand-lightGray;
}

.text-danger {
  color: $brand-red;
}

.input-underline {
  border: none;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  border-bottom: 1px solid $brand-middleGray;
  font-weight: bold;
  padding:0;
}

.no-wrap {
  white-space: nowrap;
}

.margin-bottom {
  margin-bottom: 8px;
}

.pro-btn {
  background: $brand-blue;
  color: $brand-white;
  padding: 5px 8px;
  border: 1px solid $brand-blue;
  text-align: center;

  .spinner {
    margin-right: 6px;
  }

  &.btn-white {
    color: $brand-offBlack;
    background: $brand-white;
    border: 1px solid $brand-white;

    &:hover {
      color: $brand-offBlack;
      background: $brand-white;
      border: 1px solid $brand-darkYellow;
    }
  }

  &.btn-default {
    color: $brand-offBlack;
    background: $brand-white;
    border: 1px solid $brand-white;
  }

  &.btn-cancel {
    color: $brand-white;
    background: $brand-gray;
    border: 1px solid $brand-gray;
  }

  &.btn-danger {
    background: $brand-red;
    border: 1px solid $brand-red;
  }

  &.btn-success {
    background: $brand-green;
    border: 1px solid $brand-green;
  }

  &.active {
    color: $brand-white;
    background: $brand-blue;
    border: 1px solid $brand-blue;
  }

  &:hover {
    background: $brand-darkYellow;
    border: 1px solid $brand-darkYellow;
    color: $brand-white;
    text-decoration: none;
  }
}

.center-item {
  display: flex;
  align-items: center !important;
  justify-items: center !important;
  align-content: center !important;
}

.clear {
  clear:both;
}

.badgify {
  background-color: white;
  border-radius: 50%;
  padding: 1px;
}

pre.plain {
  padding:0;
  margin:0;
  font: inherit;
  line-height: inherit;
  color:inherit;
  background: inherit;
  border: none;
  word-break: break-word;
  white-space: pre-wrap;
}

.text-inherit {
  color:inherit;
}